import React from 'react';
import cloudiness from '../images/weather/ic_cloudiness.svg';
import fog from '../images/weather/ic_fog.svg';
import hail from '../images/weather/ic_hail.svg';
import icy from '../images/weather/ic_icy conditions.svg';
import lightning from '../images/weather/ic_lightning.svg';
import rain from '../images/weather/ic_rain.svg';
import snowCover from '../images/weather/ic_snow cover.svg';
import snow from '../images/weather/ic_snow.svg';
import tornado from '../images/weather/ic_tornado.svg';
import wind from '../images/weather/ic_wind.svg';
import * as styles from './CategoryCheckbox.css';

const getIcon = (icon: string): string => {
    switch (icon) {
        case 'rain':
            return rain;
        case 'cloudiness':
            return cloudiness;
        case 'wind':
            return wind;
        case 'hail':
            return hail;
        case 'lightning':
            return lightning;
        case 'fog':
            return fog;
        case 'snow cover':
            return snowCover;
        case 'snow':
            return snow;
        case 'icy conditions':
            return icy;
        case 'tornado':
            return tornado;
        default:
            return '';
    }
};

const CategoryCheckbox = ({
    label,
    checked,
    onChange,
    icon,
    imageNumber,
}: {
    label: string;
    checked: boolean;
    onChange: React.ChangeEventHandler;
    icon: string;
    imageNumber: string | undefined;
}): React.ReactElement => {
    return (
        <>
            <label className={styles.catLabel} htmlFor={label}>
                <div className={styles.catContainer}>
                    <div className={styles.catTitle}>
                        <input
                            id={label}
                            className={styles.catCheckbox}
                            key={label}
                            type="checkbox"
                            onChange={onChange}
                            checked={checked}
                        />
                        <span className={styles.checkbox} />
                        <img
                            className={styles.weatherIcon}
                            src={getIcon(icon)}
                            alt=""
                        ></img>
                        <div> {label}</div>
                    </div>
                    {imageNumber ? (
                        <div
                            className={
                                imageNumber !== '0/0'
                                    ? styles.catNumber.default
                                    : styles.catNumber.empty
                            }
                        >
                            {imageNumber}
                        </div>
                    ) : (
                        <div className={styles.catNumber.skelton}></div>
                    )}
                </div>
            </label>
        </>
    );
};

export default CategoryCheckbox;
