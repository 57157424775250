import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useEffect, useState } from 'react';
import InvalidIcon from '../images/ic_invalid-weather.svg';
import InvalidImage from '../images/invalid-image.svg';
import { CategoryCounts, Weather, WeatherImage } from '../types/Gallery';
import Carousel from './Carousel';
import * as styles from './Gallery.css';

const Gallery = ({
    catCount,

    categories,
    weatherImages,
}: {
    catCount: CategoryCounts;
    categories: string[];
    weatherImages: WeatherImage[];
}): React.ReactElement => {
    const [showFullImg, setShowFullImg] = useState(false);
    const [showCount, setShowCount] = useState(true);
    const { t } = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const getDate = (start: number): string => {
        const startDate = new Date(start);
        const date = `0${startDate.getDate()}`;
        const month = `0${startDate.getMonth() + 1}`;
        const year = startDate.getFullYear();
        const hour = `0${startDate.getHours()}`;
        const minute = `0${startDate.getMinutes()}`;
        return `${hour.slice(-2)}:${minute.slice(-2)} —  ${date.slice(
            -2,
        )}.${month.slice(-2)}.${year}`;
    };

    const getAuspraegung = (weather: string): string => {
        return t(`crowdsourcing_attribute_${weather}`);
    };

    const control = (): void => {
        let main = document.querySelector('#main');
        if (main) {
            if (main?.scrollTop > 100) {
                setShowCount(false);
            } else {
                setShowCount(true);
            }
        }
        if (showCount) {
            setTimeout(function () {
                setShowCount(false);
            }, 3000);
        }
    };

    useEffect(() => {
        let main = document.querySelector('#main');
        if (main) {
            main.addEventListener('scroll', () => {
                control();
            });
        }
        let sidebar = document.querySelector('#sidebar');
        if (sidebar) {
            sidebar.addEventListener('click', () => {
                control();
            });
        }
    });

    useEffect(() => {
        if (showFullImg) {
            setShowCount(false);
        }
    }, [showFullImg]);

    const getImgCount = (): number => {
        let count = 0;
        if (categories)
            categories.forEach((e) => {
                (Object.keys(Weather) as Array<keyof typeof Weather>).forEach(
                    (key) => {
                        if (e === key) {
                            count += catCount[key].withImg;
                        }
                    },
                );
            });
        return count;
    };

    return (
        <>
            {showFullImg && (
                <Carousel
                    images={weatherImages
                        .slice()
                        .sort(
                            (a, b) =>
                                b.properties.submissionTimestamp -
                                a.properties.submissionTimestamp,
                        )}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    setShowCarousel={setShowFullImg}
                />
            )}
            {showCount && (
                <div className={styles.imgInfo}>
                    {getImgCount()} Meldungen mit Bild
                </div>
            )}
            <div className={styles.gallery}>
                {weatherImages
                    .slice()
                    .sort(
                        (a, b) =>
                            b.properties.submissionTimestamp -
                            a.properties.submissionTimestamp,
                    )
                    .map((img, i) => (
                        <div
                            onClick={() => {
                                setShowFullImg(true);
                                setCurrentImageIndex(i);
                            }}
                            key={img.properties.meldungId}
                            className={styles.cardView}
                        >
                            <div className={styles.imgContainer}>
                                <img
                                    className={styles.imgResponsive}
                                    alt=""
                                    src={img.properties.imageThumbnailUrl}
                                />
                            </div>
                            <div className={styles.textContainer}>
                                <div className={styles.timeText}>
                                    {getDate(img.properties.timestamp)}
                                </div>
                                <div className={styles.placeText}>
                                    {img.properties.place}
                                </div>
                                <div className={styles.weatherCondition}>
                                    {`${
                                        img.properties.category
                                            .charAt(0)
                                            .toUpperCase() +
                                        img.properties.category
                                            .slice(1)
                                            .toLowerCase()
                                    }: ${getAuspraegung(
                                        img.properties.auspraegung,
                                    )}`}
                                </div>
                                {!img.properties.qualityCheckPassed && (
                                    <div className={styles.qualityMsg}>
                                        {img.properties.qualityCheckMsg &&
                                        img.properties.qualityCheckMsg.startsWith(
                                            'user temporarily blocked',
                                        ) ? (
                                            <div className={styles.invalidIcon}>
                                                <img src={InvalidIcon} alt="" />
                                            </div>
                                        ) : (
                                            <div className={styles.invalidIcon}>
                                                <img
                                                    src={InvalidImage}
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                        <div className={styles.invalidMsg}>
                                            {img.properties.qualityCheckMsg}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default Gallery;
