import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import LoadingIndicator from '../components/LoadingIndicator';
import { Categories, PickedDate, Validity } from '../types/Gallery';
import * as styles from './App.css';
import Main from './Main';
import Sidebar from './Sidebar';
import { themeClass } from './theme.css';

const API_URL = process.env.REACT_APP_API_URL;

const App = (): React.ReactElement => {
    const [categories, setCategories] = useState<string[]>(Categories);
    const auth = useAuth();

    const [validity, setValidity] = useState(Validity.ALL);
    const [showSidebar, setShowSidebar] = useState(true);
    const [date, setDate] = useState<PickedDate>({
        from: new Date().setDate(new Date().getDate() - 1),
        to: new Date().getTime(),
    });

    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.signinRedirect();
    }

    const getCategories = useCallback(() => {
        return categories.join(',');
    }, [categories]);

    const fetchMeldungen = async () => {
        const urlSearchParams = new URLSearchParams({
            from: `${date.from}`,
            to: `${date.to}`,
            categories: getCategories(),
        });

        if (typeof validity === 'string') {
            urlSearchParams.append('quality', validity);
        }

        const response = await fetch(
            `${API_URL}/api/web/v1/meldungen?` + urlSearchParams,
            {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            },
        );

        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        return response.json();
    };

    const { data, isLoading, error, isFetching } = useQuery({
        queryKey: ['meldungen', date, validity, auth, categories],
        queryFn: fetchMeldungen,
    });

    const handleResize = (): void => {
        if (window.innerWidth < 768) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isQueryLoading = isLoading || isFetching;

    const {
        categoryCounts: catCount,
        qualityCounts: qualityCount,
        meldungen: images,
    } = data || {};

    return (
        <div className={`${styles.app} ${themeClass}`}>
            <>
                {showSidebar && (
                    <Sidebar
                        catCount={catCount}
                        qualityCount={qualityCount}
                        categories={categories}
                        setCategories={setCategories}
                        validity={validity}
                        setValidity={setValidity}
                        setShowSidebar={setShowSidebar}
                        date={date}
                        setDate={setDate}
                    />
                )}
                <>
                    {isQueryLoading ? (
                        <LoadingIndicator />
                    ) : !error ? (
                        <Main
                            catCount={catCount}
                            categories={categories}
                            weatherImages={images}
                            showSidebar={showSidebar}
                            setShowSidebar={setShowSidebar}
                        />
                    ) : (
                        <div className={styles.error}>
                            Daten konnten nicht geladen werden
                        </div>
                    )}
                </>
            </>
        </div>
    );
};

export default App;
