import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import CalenderBtn from '../images/ic-calendar standard.svg';
import CalenderBtnHover from '../images/ic-calendar.svg';
import CloseBtnHover from '../images/ic-close hover.svg';

import * as styles from './Datepicker.css';
import SelectChoice from './SelectChoice';
import './Calendar.css';
import { PickedDate, TimeFrame } from '../types/Gallery';

const DatePicker = ({
    date,
    setDate,
}: {
    date: PickedDate;
    setDate: (d: PickedDate) => void;
}): React.ReactElement => {
    const [imgSrc, setImgSrc] = useState(CalenderBtn);
    const [showCalender, setShowCalender] = useState(false);
    const [timeFrame, setTimeFrame] = useState<TimeFrame>(TimeFrame.DAY);
    const [calendarView, setCalendarView] = useState(false);
    const mouseOverHandler = () => {
        setImgSrc(CalenderBtnHover);
    };
    const mouseOutHandler = () => {
        setImgSrc(CalenderBtn);
    };
    const getDate = (): string => {
        let fromDay = new Date(date.from).getDate();
        let fromMonth = new Date(date.from).getMonth();
        let toDay = new Date(date.to).getDate();
        let toMonth = new Date(date.to).getMonth();
        let toYear = new Date(date.to).getFullYear();
        return `${fromDay}.${fromMonth} - ${toDay}.${toMonth}.${toYear}`;
    };

    useEffect(() => {
        let main = document.querySelector('#main');
        if (main) {
            main.addEventListener('scroll', () => {
                setShowCalender(false);
            });
            main.addEventListener('click', () => {
                setShowCalender(false);
            });
        }
        let sidebar = document.querySelector('#sidebar');
        if (sidebar) {
            sidebar.addEventListener('scroll', () => {
                setShowCalender(false);
            });
        }
    });

    return (
        <>
            {!calendarView ? (
                <div className={styles.datePickerContainer}>
                    <SelectChoice
                        options={[
                            {
                                value: TimeFrame.THREEHOURS,
                                label: 'Letzte 3h',
                            },
                            {
                                value: TimeFrame.DAY,
                                label: 'Letzte 24h',
                            },
                            {
                                value: TimeFrame.WEEK,
                                label: 'Letzte 7 Tage',
                            },
                            {
                                value: TimeFrame.MONTH,
                                label: 'Letzte 30 Tage',
                            },
                        ]}
                        value={timeFrame}
                        onChange={(v: string) => {
                            let myCurrentDate = new Date();

                            switch (v) {
                                case TimeFrame.THREEHOURS:
                                    setTimeFrame(TimeFrame.THREEHOURS);
                                    myCurrentDate.setHours(
                                        myCurrentDate.getHours() - 3,
                                    );
                                    setDate({
                                        from: myCurrentDate.getTime(),
                                        to: new Date().getTime(),
                                    });

                                    break;
                                case TimeFrame.DAY:
                                    setTimeFrame(TimeFrame.DAY);
                                    setDate({
                                        from: new Date().setDate(
                                            new Date().getDate() - 1,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                                case TimeFrame.WEEK:
                                    setTimeFrame(TimeFrame.WEEK);
                                    setDate({
                                        from: myCurrentDate.setDate(
                                            myCurrentDate.getDate() - 7,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                                case TimeFrame.MONTH:
                                    setTimeFrame(TimeFrame.MONTH);
                                    setDate({
                                        from: myCurrentDate.setDate(
                                            myCurrentDate.getDate() - 30,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                            }
                        }}
                    />
                    <button
                        onMouseOver={mouseOverHandler}
                        onMouseOut={mouseOutHandler}
                        className={styles.calenderBtn}
                        onClick={() => {
                            if (showCalender) {
                                setShowCalender(false);
                            } else {
                                setShowCalender(true);
                            }
                        }}
                    >
                        <img src={imgSrc} alt="" />
                    </button>
                </div>
            ) : (
                <div className={styles.datePickerContainer}>
                    <SelectChoice
                        small={true}
                        options={[
                            {
                                value: TimeFrame.THREEHOURS,
                                label: 'Letzte 3h',
                            },
                            {
                                value: TimeFrame.DAY,
                                label: 'Letzte 24h',
                            },
                            {
                                value: TimeFrame.WEEK,
                                label: 'Letzte 7 Tage',
                            },
                            {
                                value: TimeFrame.MONTH,
                                label: 'Letzte 30 Tage',
                            },
                        ]}
                        value={''}
                        onChange={(v: string) => {
                            let myCurrentDate = new Date();

                            switch (v) {
                                case TimeFrame.THREEHOURS:
                                    setTimeFrame(TimeFrame.THREEHOURS);
                                    myCurrentDate.setHours(
                                        myCurrentDate.getHours() - 3,
                                    );
                                    setDate({
                                        from: myCurrentDate.getTime(),
                                        to: new Date().getTime(),
                                    });
                                    break;
                                case TimeFrame.DAY:
                                    setTimeFrame(TimeFrame.DAY);
                                    setDate({
                                        from: new Date().setDate(
                                            new Date().getDate() - 1,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                                case TimeFrame.WEEK:
                                    setTimeFrame(TimeFrame.WEEK);
                                    setDate({
                                        from: myCurrentDate.setDate(
                                            myCurrentDate.getDate() - 7,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                                case TimeFrame.MONTH:
                                    setTimeFrame(TimeFrame.MONTH);
                                    setDate({
                                        from: myCurrentDate.setDate(
                                            myCurrentDate.getDate() - 30,
                                        ),
                                        to: new Date().getTime(),
                                    });
                                    break;
                            }
                            setCalendarView(false);
                        }}
                    />
                    <button
                        onMouseOver={mouseOverHandler}
                        onMouseOut={mouseOutHandler}
                        className={styles.bigCalendarBtn}
                        onClick={() => {
                            if (showCalender) {
                                setShowCalender(false);
                            } else {
                                setShowCalender(true);
                            }
                        }}
                    >
                        <div className={styles.dateString}>{getDate()}</div>
                        <img src={CloseBtnHover} alt="" />
                    </button>
                </div>
            )}
            {showCalender && (
                <div className={styles.calenderContainer}>
                    <Calendar
                        onChange={(date: Date[]) => {
                            let sortedDate = date.sort(
                                (a, b) => a.getTime() - b.getTime(),
                            );
                            if (sortedDate[1]) {
                                setDate({
                                    from: sortedDate[0].getTime(),
                                    to: sortedDate[1].getTime(),
                                });
                                setShowCalender(false);
                                setCalendarView(true);
                            } else {
                                const d = sortedDate[0];
                                const newD = new Date(
                                    d.getTime() + 60 * 60 * 24 * 1000,
                                );
                                setDate({
                                    from: newD.getTime(),
                                    to: sortedDate[0].getTime(),
                                });
                            }
                        }}
                        value={[new Date(date.from), new Date(date.to)]}
                        selectRange={true}
                        allowPartialRange={true}
                        formatShortWeekday={(locale, value) =>
                            ['S', 'M', 'D', 'M', 'D', 'F', 'S'][value.getDay()]
                        }
                        next2Label={null}
                        prev2Label={null}
                        showNeighboringMonth={false}
                    />
                </div>
            )}
        </>
    );
};
export default DatePicker;
