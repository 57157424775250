import { TranslationProvider } from '@ubique-innovation/react-translations';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

import { RecoilRoot } from 'recoil';
import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import App from './containers/App';
import translationsDe from './translations/de/translations.json';

const queryClient = new QueryClient();

const resources = {
    de: translationsDe,
};
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
const authorityUrl = process.env.REACT_APP_ISSUER_URL
    ? process.env.REACT_APP_ISSUER_URL
    : '/issuer';
const clientId = 'meteomeldungen-web';

const authConfig: AuthProviderProps = {
    authority: authorityUrl,
    client_id: clientId,
    redirect_uri: `${window.location.origin}/signin-oidc`,
    response_mode: 'fragment',
    scope: 'openid profile met.crowd.read offline_access',
    post_logout_redirect_uri: `${window.location.origin}/`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

root.render(
    <React.StrictMode>
        <TranslationProvider resources={resources} defaultLang={'de'}>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <BrowserRouter>
                        <AuthProvider {...authConfig}>
                            <App />
                        </AuthProvider>
                    </BrowserRouter>
                </RecoilRoot>
            </QueryClientProvider>
        </TranslationProvider>
    </React.StrictMode>,
);
