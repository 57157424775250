import React from 'react';
import Gallery from '../components/Gallery';
import MobileHeader from '../components/MobileHeader';
import { CategoryCounts, WeatherImage } from '../types/Gallery';
import * as styles from './Main.css';

const Main = ({
    catCount,
    categories,
    weatherImages,
    showSidebar,
    setShowSidebar,
}: {
    catCount: CategoryCounts;
    categories: string[];
    weatherImages: WeatherImage[];
    showSidebar: boolean;
    setShowSidebar: (d: boolean) => void;
}): React.ReactElement => {
    return (
        <div className={styles.main} id="main">
            <MobileHeader
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <Gallery
                catCount={catCount}
                categories={categories}
                weatherImages={weatherImages}
            />
        </div>
    );
};

export default Main;
