import React from 'react';
import * as styles from './MobileHeader.css';
import settingBtn from '../images/ic-setting adjust.svg';

const MobileHeader = ({
    showSidebar,
    setShowSidebar,
}: {
    showSidebar: boolean;
    setShowSidebar: (d: boolean) => void;
}): React.ReactElement => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.meteoTitle}>Meteomeldungen</div>
            <button
                className={styles.settingBtn}
                onClick={() => setShowSidebar(true)}
            >
                <img
                    className={styles.settingImg}
                    src={settingBtn}
                    alt="filter btn"
                />
            </button>
        </div>
    );
};

export default MobileHeader;
