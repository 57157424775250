import React, { useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import findIndex from 'lodash/findIndex';
import clamp from 'lodash/clamp';
import defaultImg from '../images/ic-chevron down standard.svg';
import upImg from '../images/ic-chevron up.svg';
import defaultHover from '../images/ic-chevron down hover.svg';

import * as styles from './SelectChoice.css';

export interface SelectOption<T> {
    value: T;
    label: string;
}

function SelectChoice<T>({
    label,
    options,
    value,
    onChange,
    small,
}: {
    label?: string;
    options: SelectOption<T>[];
    value: T;
    onChange: (v: T) => void;
    small?: boolean;
}): React.ReactElement {
    const [id] = useState(uniqueId('select-'));
    const [open, setOpen] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0);
    const [imgSrc, setImgSrc] = useState(defaultImg);

    useEffect(() => {
        setSelectIndex(findIndex(options, (o) => o.value === value));
    }, [options, value]);

    const onKeyDown = (e: React.KeyboardEvent): void => {
        e.preventDefault();
        const key = e.key !== undefined ? e.key : e.keyCode;

        if (key === 'Enter' || key === 13) {
            onChange(options[selectIndex].value);
            setOpen(false);
        } else if (key === 'ArrowDown' || key === 40) {
            setSelectIndex(clamp(selectIndex + 1, 0, options.length - 1));
            setOpen(true);
        } else if (key === 'ArrowUp' || key === 38) {
            setSelectIndex(clamp(selectIndex - 1, 0, options.length - 1));
            setOpen(true);
        }
    };

    const mouseOverHandler = () => {
        setImgSrc(defaultHover);
    };
    const mouseOutHandler = () => {
        setImgSrc(defaultImg);
    };

    return (
        <>
            {label != null && <div className={styles.label}>{label}</div>}
            <div onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
                <label htmlFor={id} className={styles.textfield}>
                    <input
                        id={id}
                        readOnly
                        value={typeof value == 'string' ? value : 0}
                        onFocus={() => setOpen(true)}
                        onBlur={() => setOpen(false)}
                        onKeyDown={onKeyDown}
                        className={small ? styles.smallInput : styles.input}
                    />
                    {!small ? (
                        <img
                            className={styles.arrow}
                            src={open ? upImg : imgSrc}
                            alt="'"
                        ></img>
                    ) : (
                        <img
                            className={styles.smallArrow}
                            src={defaultHover}
                            alt="'"
                        ></img>
                    )}
                    <ul
                        className={
                            open
                                ? styles.selectDropdown.open
                                : styles.selectDropdown.closed
                        }
                    >
                        {options.map((o, i) => {
                            let buttonClass = styles.buttonClass.default;
                            if (i === selectIndex)
                                buttonClass = styles.buttonClass.selected;
                            if (o.value === value)
                                buttonClass = styles.buttonClass.active;
                            return (
                                <li key={o.label} className={styles.li}>
                                    <button
                                        className={buttonClass}
                                        onClick={() => {
                                            onChange(o.value);

                                            setOpen(false);
                                        }}
                                        type="button"
                                        aria-label={o.label}
                                    >
                                        {o.label}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </label>
            </div>
        </>
    );
}

SelectChoice.defaultProps = {
    label: undefined,
};

export default SelectChoice;
