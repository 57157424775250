import { useTranslation } from '@ubique-innovation/react-translations';
import { useEffect } from 'react';
import ArrowLeft from '../images/ic-arrow left.svg';
import ArrowRight from '../images/ic-arrow right.svg';
import CloseBtn from '../images/ic-close.svg';
import InvalidIcon from '../images/ic_invalid-weather.svg';
import InvalidImage from '../images/invalid-image.svg';
import { WeatherImage } from '../types/Gallery';
import * as styles from './Carousel.css';

const Carousel = ({
    images,
    currentImageIndex,
    setCurrentImageIndex,
    setShowCarousel,
}: {
    images: WeatherImage[];
    currentImageIndex: number;
    setCurrentImageIndex: (n: number) => void;
    setShowCarousel: (n: boolean) => void;
}) => {
    const { t } = useTranslation();
    const currentImage = images[currentImageIndex];

    const nextImage = (): void => {
        setCurrentImageIndex(
            currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0,
        );
    };

    const prevImage = (): void => {
        setCurrentImageIndex(
            currentImageIndex > 0 ? currentImageIndex - 1 : images.length - 1,
        );
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === 'Escape') {
                setShowCarousel(false);
            } else if (
                event.key === 'ArrowRight' &&
                currentImageIndex !== images.length - 1
            ) {
                nextImage();
            } else if (event.key === 'ArrowLeft' && currentImageIndex !== 0) {
                prevImage();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImageIndex]);

    const getDate = (start: number): string => {
        const startDate = new Date(start);
        const date = `0${startDate.getDate()}`;
        const month = `0${startDate.getMonth() + 1}`;
        const year = startDate.getFullYear();
        const hour = `0${startDate.getHours()}`;
        const minute = `0${startDate.getMinutes()}`;
        return `${hour.slice(-2)}:${minute.slice(-2)} —  ${date.slice(
            -2,
        )}.${month.slice(-2)}.${year}`;
    };

    const getAuspraegung = (weather: string): string => {
        return t(`crowdsourcing_attribute_${weather}`);
    };
    const url = `https://map.geo.admin.ch/#/map?&swisssearch=+${currentImage.geometry.coordinates[0]}++${currentImage.geometry.coordinates[1]}&crosshair=marker`;
    return (
        <>
            <div className={styles.fullImgContainer} />

            <div className={styles.imgContentContainer}>
                {currentImageIndex !== 0 && (
                    <button className={styles.leftArrow} onClick={prevImage}>
                        <img src={ArrowLeft} alt="prev-img" />
                    </button>
                )}
                <button
                    onClick={() => setShowCarousel(false)}
                    className={styles.closeBtn}
                >
                    <img src={CloseBtn} alt="close-btn" />
                </button>
                <div className={styles.content}>
                    <div className={styles.imgContainer}>
                        <img
                            className={styles.weatherImg}
                            src={currentImage.properties.imageUrl}
                            alt=""
                        ></img>
                        {!currentImage.properties.qualityCheckPassed && (
                            <div className={styles.qualityMsg}>
                                <div className={styles.invalidIcon}>
                                    {currentImage.properties.qualityCheckMsg &&
                                    currentImage.properties.qualityCheckMsg.startsWith(
                                        'user temporarily blocked',
                                    ) ? (
                                        <img src={InvalidIcon} alt="" />
                                    ) : (
                                        <img src={InvalidImage} alt="" />
                                    )}
                                </div>
                                <div>
                                    {currentImage.properties.qualityCheckMsg
                                        ? currentImage.properties
                                              .qualityCheckMsg
                                        : ''}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.timeText}>
                        {getDate(currentImage.properties.timestamp)}
                    </div>
                    <div className={styles.placeText}>
                        {currentImage.properties.place}
                    </div>
                    <div className={styles.weatherCondition}>
                        {`${
                            currentImage.properties.category
                                .charAt(0)
                                .toUpperCase() +
                            currentImage.properties.category
                                .slice(1)
                                .toLowerCase()
                        }: ${getAuspraegung(
                            currentImage.properties.auspraegung,
                        )}`}
                    </div>
                    <a
                        href={url}
                        className={styles.weatherCondition}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Lat: {currentImage.geometry.coordinates[1]}
                        <br></br>
                        Long: {currentImage.geometry.coordinates[0]}
                    </a>
                </div>
                {currentImageIndex !== images.length - 1 && (
                    <button className={styles.rightArrow} onClick={nextImage}>
                        <img src={ArrowRight} alt="next-img" />
                    </button>
                )}
            </div>
        </>
    );
};

export default Carousel;
